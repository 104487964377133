import React, { useEffect, useState } from 'react';
import "./common.scss"
import "./matomeReset.scss"
import "./matomeStyle.css"
import "./matomeStyle.scss"
import "./matomeStyle.css.map"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redax/reducers/rootReducer';
import Loader from '../loader/loader';
import { fetchMatomeListRequest } from '../../redax/actions/matomeListActions/matomeListActions';

// get '/recruit_site/list' #障害内容等の取得API
// get '/recruit_site/genre/:id' #障害種別と求人が紐付いてるAPI


const Matome = () => {
  const [loadScreen, setLoadScreen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matomeList = useSelector((state: RootState) => state.matomeList);
  const pageTitle = '28種の障がい者専用リクルートサイト';
    document.title = pageTitle;
    const [subMenu1, setSubMenu1] = useState<any[]>([]); 
    const [subMenu2, setSubMenu2] = useState<any[]>([]); 
    const [subMenu3, setSubMenu3] = useState<any[]>([]); 
    const [subMenu4, setSubMenu4] = useState<any[]>([]); 
    const [subMenu5, setSubMenu5] = useState<any[]>([]); 
    const [isActive, setIsActive] = useState(false);
    const [fullArray, setFullArray] = useState<any[]>([]);

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

  
    useEffect(() => {
      // Attach the event listener on component mount
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };

    }, [windowSize]);



    useEffect(() => {
      if(isActive){
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&family=Noto+Color+Emoji&family=Noto+Sans+JP:wght@100;300;400;700&display=swap&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

          const script = document.createElement('script');      
          script.src = "/assets/js/main.js";
          document.body.appendChild(script);
      
          const script0 = document.createElement('script');
          script0.type = "text/javascript";
          script0.src ="/assets/js/seamless.js";
          document.head.appendChild(script0);
          return () => {
            document.body.removeChild(script);
            document.head.removeChild(script0);
            setLoadScreen(false);
          }
      }
      
    }, [isActive]);

    useEffect(() => {
      dispatch(fetchMatomeListRequest())
    }, [dispatch]);

    useEffect(() => {
      if(matomeList.pending) {
        setSubMenu1(matomeList.matome[0]?.genres);
        setSubMenu2(matomeList.matome[1]?.genres);
        setSubMenu3(matomeList.matome[2]?.genres);
        setSubMenu4(matomeList.matome[3]?.genres);
        setSubMenu5(matomeList.matome[4]?.genres);
        setIsActive(true);
        recArr(matomeList.matome);
      }
    }, [matomeList]);

  function recArr(arr:any){
    let  tmp:any = [];
      if(arr.length > 0){
        for(let i = 0; i <arr.length; i++) {
          if(arr[i].genres.length > 0){
            for(let j = 0; j< arr[i].genres.length; j++){
              tmp.push(arr[i].genres[j]);
            }
          }
        }
        setFullArray(tmp);
      }
  }


    
  return (
    <>
      {loadScreen ? <Loader /> : null}
      <div className='matomeBody'>
        {/* <Header /> */}
        {/* <body className="page_loaded"> */}
        <div className="is_page_index">
            <header id="header" className="header">
          <div className="header_container">
            <div className="header_logo-wrap">
              <div className="header_logo">
                <div className="top">
                  <span className="text">28種の障がい者専用</span>
                </div>

                <div className="bottom">
                  <span className="text1">リクルートサイト</span>
                  <span className="text2">by パラちゃんねる</span>
                </div>
              </div>
            </div>

            <div className="sp_menu_button-wrap">
              <button id="sp_menu_button" className="sp_menu_button" type="button" role="button" data-labeltext="ハンバーガーメニュー" aria-controls="sp_menu_popup" aria-expanded="false" aria-label="ハンバーガーメニュー" aria-haspopup="true">
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>

            <nav className="pc_menu-wrap" role="navigation" aria-label="グローバルメニュー">
            <ul id="pc_menu" className="pc_menu" role="list">
                <li className="pc_menu_link_li">
                  <a className="pc_menu_link" href="/matome"><span style={{color:"black"}}>HOME</span></a>
                </li>
                <li className="pc_menu_link_li">
                  <div className="has_sub_menu">
                    <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu1" aria-haspopup="true"><span style={{color:"black"}}>身体障害</span></a>
                  </div>
                  <div id="pc_menu1" className="pc_sub_menu-wrap" aria-hidden="true">
                    <ul className="pc_sub_menu" role="list">
                      {subMenu1 && subMenu1.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="pc_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                      {/* <li><a className="pc_sub_menu_link" href="/matome/detail/?v=1">視覚障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=2">構音障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=3">吃音症</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=4">失語症</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=5">膀胱・直腸機能障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=6">内部障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=7">上肢・下肢不自由</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=8">言語機能障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=9">脳性まひ</a></li> */}
                    </ul>
                  </div>
                </li>
                <li className="pc_menu_link_li">
                  <div className="has_sub_menu">
                    <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu2" aria-haspopup="true"><span style={{color:"black"}}>発達障害</span></a>
                  </div>
                  <div id="pc_menu2" className="pc_sub_menu-wrap" aria-hidden="true">
                    <ul className="pc_sub_menu" role="list">
                    {subMenu2 && subMenu2.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="pc_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                      {/* <li><a className="pc_sub_menu_link" href="/matome/detail/?v=10">ディスレクシア（読字障害）</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=11">感覚障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=12">ディスカルキュリア（算数障害）</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=13">学習障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=14">注意欠如多動性障害（ADHD）</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=15">自閉症スペクトラム症（ASD）</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=16">発達障害</a></li> */}
                    </ul>
                  </div>
                </li>
                <li className="pc_menu_link_li">
                  <div className="has_sub_menu">
                    <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu3" aria-haspopup="true"><span style={{color:"black"}}>精神障害</span></a>
                  </div>
                  <div id="pc_menu3" className="pc_sub_menu-wrap" aria-hidden="true">
                    <ul className="pc_sub_menu" role="list">
                    {subMenu3 && subMenu3.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="pc_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                      {/* <li><a className="pc_sub_menu_link" href="/matome/detail/?v=17">うつ病</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=18">てんかん</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=19">心的外傷後ストレス（PTSD）</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=20">高次脳機能障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=21">双極性障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=22">不安障害</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=23">統合失調症</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=24">摂食障害</a></li> */}
                    </ul>
                  </div>
                </li>
                <li className="pc_menu_link_li">
                  <div className="has_sub_menu">
                    <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu4" aria-haspopup="true"><span style={{color:"black"}}>知的障害</span></a>
                  </div>
                  <div id="pc_menu4" className="pc_sub_menu-wrap" aria-hidden="true">
                    <ul className="pc_sub_menu" role="list">
                    {subMenu4 && subMenu4.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="pc_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li className="pc_menu_link_li">
                  <div className="has_sub_menu">
                    <a className="pc_menu_link" href="#" role="button" aria-expanded="false" aria-controls="pc_menu5" aria-haspopup="true"><span style={{color:"black"}}>難病指定</span></a>
                  </div>
                  <div id="pc_menu5" className="pc_sub_menu-wrap last_pc_sub_menu" aria-hidden="true">
                    <ul className="pc_sub_menu" role="list">
                    {subMenu5 && subMenu5.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="pc_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                      {/* <li><a className="pc_sub_menu_link" href="/matome/detail/?v=26">筋萎縮性側索硬化症（神経難病含む）</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=27">多発性硬化症（MS）</a></li>
                      <li><a className="pc_sub_menu_link" href="/matome/detail/?v=28">指定難病1～331番</a></li> */}
                    </ul>
                  </div>
                </li>

              </ul>
            </nav>
          </div>
            </header>
        </div>

        <div id="sp_menu_popup" className="sp_menu-wrap" aria-hidden="true">
          <nav className="sp_menu-inner" aria-label="グローバルメニュー">
            <ul className="sp_menu" role="list">
              <li>
                <a className="sp_menu_link sp_menu_link_arrow" href="/matome" role="button"><span>HOME</span></a>
              </li>
              <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                  <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu1"><span>身体障害</span></a>
                </div>
                <div id="sp_menu1" className="sp_sub_menu-wrap " aria-hidden="true">
                  <ul className="sp_sub_menu" role="list">
                    {subMenu1 && subMenu1.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="sp_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                    {/* <li><a className="sp_sub_menu_link" href="/matome/detail/?v=1">視覚障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=2">構音障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=3">吃音症</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=4">失語症</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=5">膀胱・直腸機能障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=6">内部障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=7">上肢・下肢不自由</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=8">言語機能障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=9">脳性まひ</a></li> */}
                  </ul>
                </div>
              </li>
              <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                  <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu2"><span>発達障害</span></a>
                </div>
                <div id="sp_menu2" className="sp_sub_menu-wrap" aria-hidden="true">
                  <ul className="sp_sub_menu" role="list">
                    {subMenu2 && subMenu2.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="sp_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                    {/* <li><a className="sp_sub_menu_link" href="/matome/detail/?v=10">ディスレクシア（読字障害）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=11">感覚障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=12">ディスカルキュリア（算数障害）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=13">学習障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=14">注意欠如多動性障害（ADHD）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=15">自閉症スペクトラム症（ASD）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=16">発達障害</a></li> */}
                  </ul>
                </div>
              </li>
              <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                  <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu3"><span>精神障害</span></a>
                </div>
                <div id="sp_menu3" className="sp_sub_menu-wrap" aria-hidden="true">
                  <ul className="sp_sub_menu" role="list">
                  {subMenu3 && subMenu3.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="sp_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                    {/* <li><a className="sp_sub_menu_link" href="/matome/detail/?v=17">うつ病</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=18">てんかん</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=19">心的外傷後ストレス（PTSD）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=20">高次脳機能障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=21">双極性障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=22">不安障害</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=23">統合失調症</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=24">摂食障害</a></li> */}
                  </ul>
                </div>
              </li>
              <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                  <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu4"><span>知的障害</span></a>
                </div>
                <div id="sp_menu4" className="sp_sub_menu-wrap" aria-hidden="true">
                  <ul className="sp_sub_menu" role="list">
                    {subMenu4 && subMenu4.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="sp_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                  </ul>
                </div>
              </li>
              <li>
                <div className="has_sub_menu sp_menu_link_arrow">
                  <a className="sp_menu_link" href="#" role="button" aria-expanded="false" aria-controls="sp_menu5"><span>難病指定</span></a>
                </div>
                <div id="sp_menu5" className="sp_sub_menu-wrap" aria-hidden="true">
                  <ul className="sp_sub_menu" role="list">
                    {subMenu5 && subMenu5.map((data) => (
                          <li key={data.id} value={data.id}>
                              <a className="sp_sub_menu_link" href={"/matome/detail/" + data.id}>{data.name}</a>
                          </li>
                      ))}
                    {/* <li><a className="sp_sub_menu_link" href="/matome/detail/?v=26">筋萎縮性側索硬化症（神経難病含む）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=27">多発性硬化症（MS）</a></li>
                    <li><a className="sp_sub_menu_link" href="/matome/detail/?v=28">指定難病1～331番</a></li> */}
                  </ul>
                </div>
              </li>
            </ul>
            <div className="sp_menu_logo-wrap">
              <picture className="sp_menu_logo" aria-hidden="true">
                <img src="assets/img/footer_logo.png" alt="障がい者雇用の求人サイト パラちゃんねる" width="332" height="272" loading="lazy"/>
              </picture>
            </div>
          </nav>
        </div>

        <main>
            <div className="section_mvv">
              <div className="contents_container">
                <div className="mv_title-wrap">
                  <h1 className="mv_title">28種の<br aria-hidden="true"/>障がい者専用<br aria-hidden="true"/>リクルート<br aria-hidden="true" className="sp_only"/>サイト</h1>
                </div>
              </div>
            </div>

            <section className="section01">
              <div className="contents_container">
              {windowSize.width >= 768 ?
              <div className="title-wrap">
                <h2 className="title">
                  <span><span className="title_bg">あなたの持つ障がいに</span></span><br aria-hidden="true"/>
                  <span><span className="title_bg">「配慮があるお仕事」だけを</span></span><br aria-hidden="true"/>
                  <span><span className="title_bg">探せる求人サイトを制作中。</span></span></h2>
              </div>
              : 
              <div className="title-wrap">
                <h2 className="title">
                  <span><span className="title_bg">あなたの持つ障がいに配慮があるお仕事」だけを探せる求人サイトを制作中。</span></span></h2>
              </div>
              }
                
                <p className="text1">障がい者のためのリクルートサイトは世の中に複数あります。 でも障がい者全体で括られても探しにくいですよね。 あなたが例えば「聴覚障害」の場合、聴覚障害の人専用リクルートサイトがあった方が、もっと探しやすいはずです。 そこで、パラちゃんねるでは、28種類の専用リクルートサイトをつくることにしました。</p>
                <p className="text1">
                しかし…、このサイトはまだ未完成です。雇用する企業側も、28種のご回答を頂くのは、なかなか時間のかかる作業で、まだまだ情報が集められておりません。
                </p>
                <p className="text1">
                そこで企業の皆様へお願いです。28種すべての回答は難しくても、ぜひお答えできる所からお返事を頂けたらと思います。そして、少しずつでも、このサイトを募集数を充実させていけたらと思います。
                </p>
                <p className="text1">
                現在求職中の方へ。パラちゃんねる本体では、ここまで細分化はできていませんが、たくさん求人掲載はありますので、そちらもご利用ください。
                </p>
              </div>

              <div className="image1-wrap">
                <picture className="image1">
                  <source srcSet="assets/img/img1.jpg" width="1400" height="786" media="(min-width: 990px)"/>
                  <img src="assets/img/img1_sp.jpg" alt="例えば「聴覚障害」の場合、聴覚障害の人専用リクルートサイトのイメージ" width="750" height="786"/>
                </picture>
              </div>
            </section>

            <section className="section02">
              <div className="contents_container">
                <p className="text1">※音声読み上げソフトをご利用の方へ。
                  これより下に、28種類の専用サイトへのリンクが並びます。
                  文字の認識がしづらかったり、苦手な方向けに最初のエリアにリンクをまとめています。
                  対象となるボタンをクリックしてお進みください。</p>

                <div className="page_list-wrap">
                  <ul className="page_list" role="list" aria-label="28種類の専用サイトの一覧">
                    {fullArray && fullArray.map((data) => (
                          <li key={data.id}>
                            <a href={"/matome/detail/" + data.id}  className="page_card">
                              <p className="count">{data.recruitment_count}<span>件</span></p>
                              <div className="card_body">
                                <h3 className="title">{data.name}</h3>
                                <p className="sub_title">専用のリクルートサイト</p>
                              </div>
                            </a>
                        </li>
                      ))}

                    
                                {/* <li>
                      <a href="/matome/detail/?v=2" className="page_card">
                        <p className="count">2<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">構音障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=3" className="page_card">
                        <p className="count">3<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">吃音症</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=4" className="page_card">
                        <p className="count">4<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">失語症</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=5" className="page_card">
                        <p className="count">5<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">膀胱・直腸機能障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=6" className="page_card">
                        <p className="count">6<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">内部障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=7" className="page_card">
                        <p className="count">7<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">上肢・下肢不自由</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=8" className="page_card">
                        <p className="count">8<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">言語機能障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=9" className="page_card">
                        <p className="count">9<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">脳性まひ</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=10" className="page_card">
                        <p className="count">10<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">ディスレクシア<br/>
        （読字障害）</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=11" className="page_card">
                        <p className="count">11<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">感覚障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=12" className="page_card">
                        <p className="count">12<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">ディスカルキュリア<br/>
        （算数障害）</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=13" className="page_card">
                        <p className="count">13<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">学習障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=14" className="page_card">
                        <p className="count">14<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">注意欠如多動性障害<br/>
        （ADHD）</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=15" className="page_card">
                        <p className="count">15<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">自閉症スペクトラム症<br/>
        （ASD）</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=16" className="page_card">
                        <p className="count">16<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">発達障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=17" className="page_card">
                        <p className="count">17<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">うつ病</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=18" className="page_card">
                        <p className="count">18<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">てんかん</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=19" className="page_card">
                        <p className="count">19<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">心的外傷後ストレス<br/>
        （PTSD）</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=20" className="page_card">
                        <p className="count">20<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">高次脳機能障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=21" className="page_card">
                        <p className="count">21<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">双極性障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=22" className="page_card">
                        <p className="count">22<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">不安障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=23" className="page_card">
                        <p className="count">23<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">統合失調症</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=24" className="page_card">
                        <p className="count">24<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">摂食障害</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=25" className="page_card">
                        <p className="count">25<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">ちてきしょうがい</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=26" className="page_card">
                        <p className="count">26<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">筋萎縮性側索硬化症<br/>
        （神経難病含む）</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=27" className="page_card">
                        <p className="count">27<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">多発性硬化症<br/>
        （MS）</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li>
                                <li>
                      <a href="/matome/detail/?v=28" className="page_card">
                        <p className="count">28<span>件</span></p>
                        <div className="card_body">
                          <h3 className="title">指定難病1～331番</h3>
                          <p className="sub_title">専用のリクルートサイト</p>
                        </div>
                      </a>
                    </li> */}
                              </ul>
                </div>
                {windowSize.width <= 768
                ? <p className="text2">※ここから下は、私たちパラちゃんねるの自己紹介になります。
                自分の障がいに合った職種をお探しの方は、上記リンクからお探し頂き、ボタンをクリックしてください。</p>
                 : 
                <p className="text2">※ここから下は、私たちパラちゃんねるの自己紹介になります。<br aria-hidden="true"/>
                  自分の障がいに合った職種をお探しの方は、上記リンクからお探し頂き、ボタンをクリックしてください。</p>
                }

                
              </div>
            </section>

          </main>

            <div className="sub_footer">
              <div className="sub_footer_mv-wrap">
                <picture className="sub_footer_mv">
                  <source srcSet="assets/img/img2.jpg" width="1400" height="786" media="(min-width: 990px)"/>
                  <img src="assets/img/img2_sp.jpg" alt="パラちゃんねるの特長のイメージ" width="750" height="786" loading="lazy"/>
                </picture>
              </div>

              <div className="contents_container">
                <div className="title-wrap">
                  <div  className="title-wrap">
                    <h2 className="title">
                      <span><span className="title_bg">障がい者雇用の</span></span><br aria-hidden="true" className="sp_only"/>
                      <span><span className="title_bg">求人サイト</span></span><br aria-hidden="true"/>
                      <span><span className="title_bg">パラちゃんねるの特長</span></span>
                    </h2>

                  </div>

                <div className="message_list-wrap">
                  <ul className="message_list" role="list">
                    {/* <li><span className="emoji" aria-hidden="true"></span>特長１：エントリー時点での志望動機が不要！</li> */}
                    <li>
                      <span className="emoji" aria-hidden="true">
                        <img src="assets/img/star.png" alt="Star Emoji" style={{ width: "1em", display:'inline-block', verticalAlign:'middle', marginBottom:'5px' }} />
                      </span>
                      特長１：エントリー時点での志望動機が不要！
                    </li>
                    <li><span className="emoji" aria-hidden="true">
                        <img src="assets/img/star.png" alt="Star Emoji" style={{ width: "1em", display:'inline-block', verticalAlign:'middle', marginBottom:'5px' }} />
                      </span>特長２：スキルだけでなく相性を重視したマッチング</li>
                    <li><span className="emoji" aria-hidden="true">
                        <img src="assets/img/star.png" alt="Star Emoji" style={{ width: "1em", display:'inline-block', verticalAlign:'middle', marginBottom:'5px' }} />
                      </span>特長３：学歴や職歴数ではなく長所や強みに焦点を当てたマイページ</li>
                    <li><span className="emoji" aria-hidden="true">
                        <img src="assets/img/star.png" alt="Star Emoji" style={{ width: "1em", display:'inline-block', verticalAlign:'middle', marginBottom:'5px' }} />
                      </span>特長４：企業から直接声がかかる</li>
                    <li><span className="emoji" aria-hidden="true">
                        <img src="assets/img/star.png" alt="Star Emoji" style={{ width: "1em", display:'inline-block', verticalAlign:'middle', marginBottom:'5px' }} />
                      </span>特長５：求職者も求人企業も完全無料！</li>
                  </ul>
                </div>

                <div className="voice_group">
                  <div className="voice_title-wrap">
                    <h3 className="voice_title"><span className="emoji" aria-hidden="true">💬</span>パラちゃんねるユーザーの声</h3>
                  </div>

                  <div className="voice_list-wrap">
                    <div className="voice_list">
                      <div className="top">
                        <div className="img">
                          <img src="assets/img/voice_profile_photo01.jpg" alt="豆塚エリさんの写真" width="161" height="160" loading="lazy"/>
                        </div>

                        <p className="profile">
                          <span className="name">豆塚 エリ｜詩人</span>
                          <span className="text">頸髄損傷・車椅子</span>
                        </p>
                      </div>

                      <div className="bottom">
                        <p className="profile_text">
                          一括りに「障害」と言っても、種別や個人によって状態は様々。
                          車いすユーザーの私は通勤に困難を抱えているので、在宅ワークに絞って検索をかけられるのが便利だと思いました。</p>
                      </div>
                    </div>

                    <div className="voice_list">
                      <div className="top">
                        <div className="img">
                          <img src="assets/img/voice_profile_photo02.jpg" alt="古川諭香さんの写真" width="161" height="160" loading="lazy"/>
                        </div>

                        <p className="profile">
                          <span className="name">古川 諭香｜フリーライター</span>
                          <span className="text">心疾患（単心室症・単心房症）</span>
                        </p>
                      </div>

                      <div className="bottom">
                        <p className="profile_text">
                          持病や特性を、正しく理解してもらいながら働きたいという願いが叶いやすい。
                          職場の環境や就業に関する配慮なども、事前にチェックしてから応募できるので安心できます。</p>
                      </div>
                    </div>

                    <div className="voice_list">
                      <div className="top">
                        <div className="img">
                          <img src="assets/img/voice_profile_photo03.jpg" alt="がんちゃんの写真" width="161" height="160" loading="lazy"/>
                        </div>

                        <p className="profile">
                          <span className="name">がんちゃん</span>
                          <span className="text">先天性感音難聴</span>
                        </p>
                      </div>

                      <div className="bottom">
                        <p className="profile_text">
                          パラちゃんねるは、求人企業側と私たち求職者側との間に発生しがちな「情報の非対称性」を解消してくれます。
                          特に、相性を重視したマッチング機能はおすすめ！</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer className="footer">

              <div className="contents_container">
                <div className="footer_logo-wrap">
                  <a className="footer_logo_link" href="https://job.parachannel.jp/" target="_blank" rel="noopener" aria-label="障がい者雇用の求人サイト パラちゃんねるを見る">
                    <picture className="footer_logo" aria-hidden="true">
                      <img src="assets/img/footer_logo.png" style={{width:'300px', height:'auto'}} alt="障がい者雇用の求人サイト パラちゃんねる" width="332" height="272" loading="lazy"/>
                    </picture>
                  </a>
                </div>

                <div className="footer_register-wrap">
                  <a className="footer_register_btn" href="https://job.parachannel.jp/signup/" target="_blank" rel="noopener" aria-label="障がい者雇用の求人サイト パラちゃんねるに無料新規登録する">
                    <picture className="footer_register" aria-hidden="true">
                      <source srcSet="assets/img/register_btn.png" width="473" height="106" media="(min-width: 990px)"/>
                      <img src="assets/img/register_btn.png"  alt="障がい者雇用の求人サイト パラちゃんねるに無料新規登録する" width="273" height="53" loading="lazy"/>
                    </picture>
                  </a>
                </div>

                <div className="footer_message-wrap">
                  <p className="footer_message" style={{textAlign:'left'}}>
                    <span className="message_block">働くことに障害のある当事者と一緒に作ったサービスです。</span>
                    <span className="message_block">不採用通知で落ち込まずに活動できるよう</span>
                    <span className="message_block">「10日以内に企業から回答がない」場合は</span>
                    <span className="message_block">自動的に不合格する仕組みを入れるなど。</span>
                    <span className="message_block">当事者の声を活かしています。ぜひとも、ご活用ください！</span>

                    <span className="footer_copy">© parachannnel</span>
                  </p>
                </div>
              </div>
            </footer>
        </div>
  {/* </body> */}
    </div>
    </>
  );
}

export default Matome
